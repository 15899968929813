import { lazy } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { DashboardIcon } from 'components/ui-kit/icons/iconComponents/DashboardIcon'
import { ROUTE_PATHS } from 'constants/index'
import config from 'config/app.config'

export const routerList = [
  {
    key: uuidv4(),
    label: 'home',
    path: ROUTE_PATHS.home,
    component: lazy(() => import('pages/Home/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Register',
    path: ROUTE_PATHS.register,
    component: lazy(() => import('pages/Register/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Login',
    path: ROUTE_PATHS.login,
    component: lazy(() => import('pages/Login/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Casino',
    path: ROUTE_PATHS.casino2,
    component: lazy(() => import('pages/Casino/Casino')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Casino',
    path: ROUTE_PATHS.casino,
    component: lazy(() => import('pages/Casino/Casino')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Live Casino',
    path: ROUTE_PATHS.liveCasino,
    component: lazy(() => import('pages/Casino/LiveCasino')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Winners',
    path: ROUTE_PATHS.winners,
    component: lazy(() => import('pages/Winners/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Reset Password',
    path: ROUTE_PATHS.resetPassword,
    component: lazy(() => import('pages/ForgotPassword/ResetPassword')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Forgot Password',
    path: ROUTE_PATHS.forgotPassword,
    component: lazy(() => import('pages/ForgotPassword/ForgetPassword')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Promotions',
    path: ROUTE_PATHS.promotions,
    component: lazy(() => import('pages/Promotions/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Profile',
    path: ROUTE_PATHS.profile,
    component: lazy(() => import('pages/Profile/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'User Limits',
    path: ROUTE_PATHS.userLimits,
    component: config.userLimits ? lazy(() => import('pages/UserLimits/index')) : lazy(() => import('components/layouts/NotFound/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'UserBalance',
    path: ROUTE_PATHS.userbalance,
    component: lazy(() => import('pages/UserBalance/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Bonus',
    path: ROUTE_PATHS.bonus,
    component: lazy(() => import('pages/Bonus/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Deposit',
    path: ROUTE_PATHS.deposit,
    component: lazy(() => import('pages/Deposit/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Deposit Successfully',
    path: ROUTE_PATHS.depositSuccessfully,
    component: lazy(() => import('pages/Casino/Casino')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Withdrawal',
    path: ROUTE_PATHS.withdrawal,
    component: lazy(() => import('pages/Withdrawal/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'WithdrawalRequest',
    path: ROUTE_PATHS.withdrawalRequest,
    component: lazy(() => import('pages/WithdrawalRequest/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Transaction',
    path: ROUTE_PATHS.transaction,
    component: lazy(() => import('pages/Transaction/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'BettingSlip',
    path: ROUTE_PATHS.bettingslip,
    component: lazy(() => import('pages/BetHistory/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'BetHistory',
    path: ROUTE_PATHS.betHistory,
    component: lazy(() => import('pages/BetHistory/index')),
    hasAuth: true,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'supprot',
    path: ROUTE_PATHS.support,
    component: lazy(() => import('pages/CMS/Supprot/Support')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'AffiliatesSignup',
    path: ROUTE_PATHS.affiliateSignup,
    component: lazy(() => import('pages/AffiliateSignup/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },

  {
    key: uuidv4(),
    label: 'Affiliates',
    path: ROUTE_PATHS.affiliates,
    component: lazy(() => import('pages/Affiliates/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'CasinoGame',
    path: ROUTE_PATHS.casinoGame,
    component: lazy(() => import('pages/Casino/components/CasinoGame/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },



  {
    key: uuidv4(),
    label: 'Influencers',
    path: ROUTE_PATHS.influencers,
    component: lazy(() => import('pages/Influencers')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'VerifyEmail',
    path: ROUTE_PATHS.verifyEmail,
    component: lazy(() => import('pages/VerifyEmail/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Loyalty',
    path: ROUTE_PATHS.loyalty,
    component: lazy(() => import('pages/Loyalty/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Kyc',
    path: ROUTE_PATHS.kyc,
    component: lazy(() => import('pages/Kyc/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Playing-Now',
    path: ROUTE_PATHS.playingNow,
    component: lazy(() => import('pages/PlayingNow/PlayingNow')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Lives',
    path: ROUTE_PATHS.lives,
    component: lazy(() => import('pages/Lives/Lives')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Provider-Games',
    path: ROUTE_PATHS.providerGames,
    component: lazy(() => import('pages/ProvidersGames/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'favourite-Games',
    path: ROUTE_PATHS.favourite,
    component: lazy(() => import('pages/Casino/components/FavouriteGames/FavouriteGames')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'sports',
    settingsProperty: 'sports',
    path: ROUTE_PATHS.sports,
    component: lazy(() => import('pages/Sports/SportsBook')),
    notFoundPage: lazy(() => import('components/layouts/NotFound/index')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Sports Transaction Report',
    path: ROUTE_PATHS.sportsTransactionReport,
    component: lazy(() => import('pages/Sports/SportTransactionReport')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'tournaments',
    path: ROUTE_PATHS.tournaments,
    component: lazy(() => import('pages/Promotions/component/Tournaments')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Cashback Booster',
    path: ROUTE_PATHS.cashbackBooster,
    component: lazy(() => import('pages/Promotions/component/CashbackBooster')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Cashback Booster',
    path: ROUTE_PATHS.casinoWelcome,
    component: lazy(() => import('pages/Promotions/component/Promotions')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },
  {
    key: uuidv4(),
    label: 'Bonus',
    path: ROUTE_PATHS.Regulation,
    component: lazy(() => import('pages/Promotions/component/Regulation')),
    hasAuth: false,
    hasNavBar: false,
    icon: DashboardIcon
  },

]
