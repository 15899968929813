// eslint-disable-next-line import/no-anonymous-default-export
export default {
  mustbeAtleast3Characters:
    "{{label}} debe tener al menos {{minNumber}} caracteres",
  cannotExceed32Characters:
    "{{label}} No puedes exceder {{maxNumber}} caracteres",
  cannotExceed15Characters:
    "{{label}} No puedes exceder {{maxNumber}} caracteres",
  isRequired: "{{text}} se requiere",
  invalidEmailFormat: "Formato de correo inválido",
  loggedInSuccess: "Iniciado sesión exitosamente",
  loggedOutSuccess: "Cerró sesión exitosamente",
  internalServerError: "Error Interno del Servidor",
  userAlreadyExists: "El usuario ya existe",
  userNotExists: "El usuario no existe",
  unAuthorized: "No autorizado",
  usernameOrEmailNotMatch:
    "Nombre de usuario/correo electrónico o contraseña no coinciden",
  usernameOrEmail: "Nombre de usuario/correo electrónico",
  username: "Nombre de usuario",
  password: "Contraseña",
  forgotPassword: "¿Has olvidado tu contraseña?",
  casino: "Casino",
  sportsbook: "Apuestas deportivas",
  promotions: "Promociones",
  winners: "Ganadores",
  searchGames: "buscar juegos",
  login: "Acceso",
  signup: "Inscribirse",
  cancel: "Cancelar",
  balance: "Balance",
  bonus: "Prima",
  deposit: "Depósito",
  withdraw: "Retirar",
  withdrawRequest: "Petición de retiro",

  logout: "Cerrar sesión",
  transactionReport: "Reporte de transacción",
  bettingSlipReport: "Informe de boleta de apuestas",
  category: "Categoría",
  support: "Apoyo",
  supportCenter: "Centro de Apoyo",
  liveSupport: "Soporte en tiempo real",
  contactUs: "Contáctenos",
  faq: "Preguntas más frecuentes",
  tnc: "Términos",
  privacyPolicy: "política de privacidad",
  cookiePolicy: "Política de cookies",
  bonusRules: "Reglas de bonificación",
  socialMedia: "Medios de comunicación social",
  facebook: "Facebook",
  twitter: "Gorjeo",
  instagram: "Instagram",
  discord: "Discordia",
  aboutUs: "Sobre nosotros",
  aboutOnlybets: "Acerca de las apuestas únicas",
  affiliates: "Afiliados",
  responsibleGaming: "Juego responsable",
  introduction: "Introducción",
  settingLimits: "Establecer límites",
  paymentMethods: "Métodos de pago seguros",
  popularProviders: "Proveedores más populares",
  copyrightMessage: "Copyrights {{year}} Todos los derechos reservados. ",
  enterEmail: "Introduce tu correo electrónico",
  enterFirstName: "Ingrese su nombre",
  enterLastName: "Ingrese su apellido",
  enterUserName: "Ingrese su nombre de usuario",
  sendEmail: "Enviar correo electrónico",
  personalInformation: "Informacion personal",
  CPF: "CPF",
  continue: "Continuar",
  nameAndSurname: "Nombre y apellido",
  dateOfBirth: "Fecha de nacimiento",
  goBack: "Regresa",
  email: "Correo electrónico",
  telephone: "Teléfono",
  eighteenPlus: "tengo más de 18 años",
  acceptTnC: "Términos y condiciones ",
  profile: "Perfil",
  loyality: "Fidelidade",
  name: "Nombre",
  surname: "Apellido",
  gender: "Género",
  dob: "Fecha de nacimiento",
  emailAddress: "Dirección de correo electrónico",
  language: "Idioma",
  timeZone: "Zona horaria",
  update: "Actualizar",
  confirmPassword: "confirmar Contraseña",
  updatePassword: "Actualiza contraseña",
  address: "DIRECCIÓN",
  residingCounty: "País de residencia",
  residingState: "Estado de residencia",
  residingCity: "Ciudad de residencia",
  pincode: "Código PIN",
  cep: "CEP",
  notinform: "Prefiero no informar",
  other: "Otro",
  userBalance: "SALDO DE USUARIO",
  bonusBalance: "Saldo de bonificación",
  insufficientBalance:
    "Actualmente no tienes saldo. Deposite fondos en su cuenta Onlybets para jugar.",
  activeBonuses: "Bonos activos",
  fulfilledBonuses: "Bonos cumplidos",
  forfeitedBonuses: "Bonos perdidos",
  paymentType: "TIPO DE PAGO",
  cryptoCurrencies: "CRIPTOMONEDAS",
  depositP1: "Pix es el pago instantáneo brasileño.",
  depositP2:
    "El método de pago creado por el Banco Central (BC) en el que los fondos se transfieren entre cuentas en unos segundos, en cualquier momento o día.",
  depositP3: "Es práctico, rápido y seguro. ",
  minimum: "Mínimo",
  maximum: "Máximo",
  processingTime: "Tiempo de procesamiento 5 minutos",
  value: "Valor",
  payment_pix: "Pago por foto",
  payment_credit: "Pago con tarjeta de crédito",
  random_key: "Clave aleatoria",
  key: "Llave",
  currency: "Divisa",
  copiedSuccessfully: "¡Copiado exitosamente!",
  cpf: "CPF",
  pix_key_type: "Tipo de clave de foto",
  pix_key: "Clave de foto",
  pix_qr_code: "Código QR PIX",
  pix_code: "Código PIX",
  copy_pix_code: "Copiar código PIX",
  expired_qr_code: "¡Código QR caducado!",
  new_qr_code: "¿Nuevo código QR?",
  pix_instructions_title: "Instrucciones para el código PIX",
  pix_instructions_item_1:
    "1. Accede al área de fotos en tu aplicación bancaria",
  pix_instructions_item_2:
    "2. Elige la opción 'Leer código QR' y utiliza la cámara de tu celular para escanear el código o copia el código alfanumérico alternativo (compuesto por números y letras) y pégalo en la opción 'PIX Copia e Cola' de la aplicación.",
  pix_instructions_item_3: "3. Confirma el pago en tu cuenta del juego.",
  successWithdrawalRequest: "Solicitud de retiro exitosa",
  withdrawalRequest: "SOLICITUD DE RETIRO",
  pixKeyTypeIsRequired: "El tipo de clave de pix es requerido",
  invalidCpfNumber: "Número CPF no válido",
  invalidEmail: "Correo electrónico no válido",
  invalidPhoneNumber: "Número de teléfono no válido",
  invalidPixRandomKey: "La clave de imagen aleatoria no es válida",
  date: "Fecha",
  time: "tiempo",
  noResultsFound: "No se han encontrado resultados",
  knowledgeBase: "Accede a nuestra base de conocimientos.",
  search: "Buscar",
  withdrawal: "Retiro",
  withdrawalP1: "El usuario no puede depositar ni retirar moneda fiduciaria. ",

  withdrawal: "Solicitud de Retiro",
  withdrawalP1: "No puedes realizar retiros a terceros.",
  withdrawalP11: "Los retiros solo se permiten para las cuentas de su propiedad y también están limitados a 1 solicitud por día.",

  ERC20Address: "Su dirección ERC20",
  withdrawalP2:
    "Asegúrese de NO ingresar la dirección BEP2, BEP20 (BSC) como billetera con dirección de retiro de LTC.",
  withdrawalP3: "NO realice retiros como transferencia entre cadenas",
  withdrawalFee: "Cargo por retiro",
  amount: "Cantidad",
  status: "Estado",
  transactionType: "tipo de transacción",
  bettingSlipId: "ID del boleto de apuestas",
  livePreMatch: " En vivo/pre-partido:",
  bettingSlipState: "Estado del boleto de apuestas",
  playingNow: "Jugando ahora",
  playNow: "Jogar ágora",
  loyalty: "Lealtad",
  myAccount: "Mi cuenta",
  reports: "Informes",

  // Banner 1
  playWithYourFavoriteInfluencer: "Juega con tu influencer favorito!",
  signUpWith100DollarBonus: "Regístrese ahora y obtenga hasta R$ 7.000,00 en bonos en su primer depósito.",
  registerNow: "Únase a nosotros, regístrese!",

  // Banner 2
  welcomePackage: "Bono de Bienvenida de hasta R$ 7.000,00",
  ClickToFirstDeposit: "Haga clic en el botón a continuación y obtenga un bono imperdible de hasta R$ 7.000,00 en su primer depósito.",
  wantBonus: "Quiero mis bonos!",


  // Banner 3
  everyTimeReward: "¡Cada vez que juegues con nosotros, serás recompensado!",
  knowLoyalty: "Descubra nuestro exclusivo programa de fidelización",
  moreInfo: "Saber más",



  // Banner 1 Cassino ao vivo
  winWithInfluencer: "¡Consigue victorias junto a tu Influencer favorito!",
  signUpNowGetBonus: "Regístrese ahora y obtenga un bono imperdible de hasta R$ 7.000,00 en su primer depósito.",
  registerNow: "¡Regístrate ahora!",


  // Banner 2 Cassino ao vivo
  feelCasinoAtmosphere: "¡Siente el ambiente del casino en casa!",
  exploreLiveGames: " Explora nuestra selección de juegos en vivo y sumérgete en la diversión.",
  wantParticipate: "¡Quiero participar!",


  // Banner 3 Cassino ao vivo
  realOpponents: "¡Desafía a oponentes reales!",
  livePoker: "Sumérgete en la acción del póquer en vivo y demuestra tus habilidades.",
  registerNow: "¡Regístrate ahora!",



  home: "Hogar",
  games: "Juegos",
  influencers: "Influencers",
  recommended: "Recomendado",
  slots: "Tragamonedas",
  jackpots: "botes",
  imLucky: "Soy suertudo",
  readMore: "Leer más",
  recentWinners: "Ganadores recientes",
  recentLargeMultiplayers: "Multijugadores grandes recientes",
  providers: "Proveedores",
  pleaseAddMinimum: "* Por favor agregue mínimo",
  filter: "Filtrar",
  noResultFound: "No se han encontrado resultados",
  type: "Tipo",
  yourFirstTime: "Tu primera vez ?",
  doubleBankroll: "¡Doble financiación! ",
  bonusFistTime: "Bono hasta R$ 100",

  descriptionHome: "y jugar junto a los mayores Influencers muy gustos.",
  fistTimeDeposit: ", en su primer depósito.",
  textHome: "Haz tu primer depósito ",
  reliableSystem: "Website confiable",
  bonusUpTo1000: "Plataforma 100% confiable",
  fastAndSecurePayment: "Retiro rápido",
  quickRegistration: "Registro rápido",
  whatOnlybetsOffersYour: "LO QUE TE OFRECE SOLAMENTE APUESTAS",
  differentialTreatmentNote: "Soporte 24/7",
  loyaltyProgram: "Programa de Fidelidade",
  quickPayment: "Pago rápido",
  almostOnTheSpot: "Casi en el acto",
  bigPrizes: "Premios semanales altos",
  playWithInfluencer: "Juega con influencers",
  newWeeklyGames: "Nuevos juegos semanales",
  bonusAndCupons: "Bonos y Cupones en todo momento",
  youPayReallyHighAmounts: "Pagas cantidades realmente altas",
  highReturns: "Altos rendimientos",
  newGamesWeekly: "Nuevos juegos semanales",
  chooseSomeoneToPlayWithYou: "¡TENGA A ALGUIEN QUE JUGUE CON USTED!",
  promotion1:
    "En Onlybets, la principal diferencia es que NUNCA estarás solo. ",
  promotion2: "Resultados 498% más altos cuando juegas con BETINA.",
  hereYouAreAPriority: "Aquí eres una prioridad.",
  twentyFourHourSupport: "Soporte las 24 horas.",
  promotion3:
    "Siempre habrá alguien que le ayude si tiene algún problema, lo cual suele ser muy raro. ",
  othersSpeakForUs: "AHORA DEJARÉ QUE LOS DEMÁS HABLAN POR NOSOTROS",
  betinasAreWaitingForYouComeNow: "BETINAS TE ESPERAN VEN YA,",
  promotion4: "Las Betinas se mueren por verte lucrar junto con ellas",
  weWantYourGood: "¡QUEREMOS TU BIEN!",
  support24x7: "SOPORTE 24/7",
  weWillGetBackToYouInRecordTime:
    "¡Nos comunicaremos con usted en un tiempo récord!",
  languages: "Idiomas",
  languagePortuguese: "Portugués",
  languageEnglish: "Inglés",
  languageSpanish: "Español",
  doesOnlybetsOfferFirstDepositBonuses:
    "¿ONLYBETS OFRECE BONOS POR PRIMER DEPÓSITO?",
  firstDepositBonus: "¡Sí! ",
  isTheWithdrawalFast: "¿LA RETIRO ES RÁPIDO?",
  withdrawalSpeed:
    "Sí, nuestra prioridad es conseguirte tu dinero lo más rápido posible para que puedas disfrutarlo lo antes posible.",
  doIHaveAccessToSupport: "¿TENGO ACCESO A SOPORTE?",
  supportAccess:
    "Tendrás acceso a soporte VIP las 24 horas del día para responder tus preguntas o resolver cualquier problema que puedas tener.",
  areYouSureYouWantToLogout: "¿Está seguro de que desea cerrar sesión?",
  yes: "Sí",
  no: "No",
  cpfIsRequired: "* Se requiere CPF",
  invalidCpf: "CPF no válido",
  nameIsRequired: "* Se requiere el nombre",
  specialCharactersAreNotAllowed: "* Caracteres especiales no están permitidos",
  dateOfBirthCannotBeInTheFuture:
    "* La fecha de nacimiento no puede ser en el futuro",
  shouldBeGreaterThanEighteenYears: "* Debe ser mayor de 18 años",
  dateOfBirthIsRequired: "* La fecha de nacimiento es obligatoria",
  usernameOrEmailIsRequired:
    "* Se requiere nombre de usuario/correo electrónico",
  invalidEmailAddress: "* Dirección de correo electrónico no válida",
  emailIsRequired: "* Correo electronico es requerido",
  passwordIsRequired: "* Se requiere contraseña",
  passwordMustBeAtLeastEightCharacters:
    "* La contraseña debe tener al menos 8 caracteres",
  passwordMustIncludeAtLeastOneUppercaseLowercaseNumber:
    "* La contraseña debe incluir al menos una letra mayúscula, una letra minúscula y un número",
  phoneNumberIsNotValid: "* El número de teléfono no es válido",
  phoneNumberIsRequired: "* El número de teléfono es obligatorio",
  youMustBeEighteenPlus: "* Debes tener 18 años",
  youMustAgreeToTheTermsAndConditions:
    "* Debes aceptar los términos y condiciones",
  reCaptchaShouldBeVerified: "* ReCaptcha debe ser verificado",
  youMustVerifyTheReCaptcha: "* Debes verificar el recaptcha",
  genderIsRequired: "* Se requiere género",
  surnameIsRequired: "* Se requiere apellido",
  usernameIsTooLong: "* El nombre de usuario es demasiado largo",
  usernameIsRequired: "* El nombre de usuario es obligatorio",
  affiliateTypeIsRequired: "* Se requiere tipo de afiliado",
  usernameInvalidCharacters: "* Introduzca sólo letras y/o números. No se permiten espacios ni caracteres especiales.",
  register: "Registrar",
  usernameIsShort: "* ¡El nombre de usuario es corto!",
  registrationCompleteDialogText:
    "Hemos enviado un enlace de verificación a su cuenta de correo electrónico registrada. ",
  ok: "DE ACUERDO",
  categories: "Categorías",
  didYouKnow: "¿Sabías?..",
  youCanUseOneOfMore:
    "Puede utilizar uno o más filtros en el menú para buscar\n  ",
  gameSearch: "Búsqueda de juegos",
  all: "Todo",
  viewAll: "Ver todo",
  liveCasino: "Casino en vivo",
  comingSoon: "Muy pronto",
  comingNewUpdate: "Estamos trabajando en algo emocionante. ",
  scrollUp: "Desplázate hacia arriba para ver más juegos...",
  loadMore: "carga más",
  level: "Nivel {{number}}",
  points: "Puntos {{number}}",
  loyaltyPoints: "Puntos de lealtad {{number}}",
  coins: "monedas {{number}}",
  loyaltyCoins: "Monedas de fidelidad {{number}}",
  perPoint: "Por punto",
  question1: "¿Cómo funciona el sistema de fidelización OnlyBets?",
  answer1:
    "Tan pronto como juegues tu primer giro en OnlyBets, comenzarás a ganar puntos y monedas de fidelidad. ",
  necessoryLoyaltyPoints:
    "Total de puntos de fidelidad para alcanzar el siguiente nivel",
  loyaltyCoinsEarnedPerLoyaltyPoint:
    "Monedas de fidelidad obtenidas por punto de fidelidad",
  maxLevel: "Máximo nivel",
  claimNow: "Reclamar ahora",
  enterValue: "Ingresar {{value}}",
  valueCantGreaterthan:
    " Ingrese el monto que no puede ser mayor que el existente {{type}} {{value}} {{currency}}",
  userLoyalty: "Fidelización de usuarios",
  progressionSystem: "Sistema de progresión",
  joinNow: "Únete ahora",
  tryForFree: "Prueba gratis",
  gameName: "Nombre del juego",
  providerName: "Proveedor",
  reset: "Reiniciar",
  uploadDocument: "Cargar documento",
  documentUploadedSuccessfully: "Documento cargado exitosamente",
  manageYourKyc: "Administre su KYC",
  canNotUploadAgain: "Una vez subido el archivo, no puedo volver a subirlo. ",
  kycStatus: "Tu archivo es {{status}} por administrador",
  fileAllowed:
    "Por favor sube solo {{files}} archivos con tamaño permitido {{size}}",
  fileAllowedFormat: "Por favor sube solo {{files}} archivos",
  fileAllowedSize: "El tamaño del archivo excede el límite {{fileSize}}. ",
  categoryName: "nombre de la categoría",
  contactWithAdmin: "Por favor contacte con el administrador",
  referAFriend: "Recomendar un amigo",
  copyTitleMessage: "por favor copie {{title}} Aplicar",
  yourRefferalCode: "Su código de referencia",
  refferalCode: "Código de referencia",
  copiedMessage: "su {{text}} copiado exitosamente",
  shareInviteCode: "Comparte tu código de invitación",
  resetPasswordLinkSentYourRegisterdEmail:
    "El enlace para restablecer la contraseña ha sido enviado a su correo electrónico.",
  resetPasswordLinkIsOnlyValidFor:
    "El enlace para restablecer contraseña es válido solo para {{time}}",
  resetPassword: "Restablecer la contraseña",
  newPassword: "Nueva contraseña",
  passwordChangedSuccessfully: "Contraseña cambiada con éxito",
  wantLogin: "¿Quieres iniciar sesión?",
  tokenExpired: "Su enlace de restablecimiento de contraseña ha caducado",
  verifyEmailAgain: "Debes verificar tu correo electrónico nuevamente.",
  verifyEmail: "Verificar correo electrónico",
  userInformation: " informacion del usuario",
  youDeserve: "Te lo mereces ",
  ourBrandNew: "nuestro nuevo",
  loyaltySystem: "Sistema de fidelización",
  playGameHandleRest:
    "¡Simplemente juega los juegos que te gustan y nosotros nos encargaremos del resto!",
  earnCoinsSpinning: "Gana monedas girando",
  earnLoyaltyPointsCoinsOnPlayCasino:
    " Siempre que juegue en nuestro casino o casino en vivo, ganará puntos de fidelidad y monedas de fidelidad.",
  alwaysRewardAtOnlybets: "Siempre recibirás recompensas en OnlyBets",
  onlyBetsWeMakeSureThat: "En OnlyBets nos aseguramos de que...",
  everySpinCounts: "...¡cada giro cuenta!",
  wantPlayerAlwaysRewarded:
    "Queremos asegurarnos de que los jugadores siempre sean recompensados ​​cuando juegan en OnlyBets. ",
  perentGameAward: "{{percent}} de nuestros juegos de casino te premiarán",
  wheneverWinLossLoyaltyPointAndCoins:
    "con puntos de fidelidad y monedas de fidelidad siempre que juegues, ¡ganes o pierdas!",
  lastLoginTime: "Hora del último inicio de sesión",
  loginFirst: "No has iniciado sesión, inicia sesión primero",
  prev: "Anterior",
  next: "Próximo",
  page: "Página",
  bonusCode: "Código extra",
  wwwOnlybets:
    " www.onlybets.tv es operado por OnlyBets B.V. (Registro Comercial de Curazao no. 166128 Emancipatie Boulevard 31, Curazao) bajo una sublicencia CIL de conformidad con la Licencia maestra de juego №5536/JAZ",
  antiMoneyLaundering: "Anti lavado de dinero",
  siteUnderDevelopment: "El sitio web está en desarrollo. ",
  earnuptoBRLOnPerInvitation: "Gana hasta 50 BRL por invitación",
  refreshPage: "Actualizar página",
  inviteFriendandEarnMoney: "Invita a un amigo y gana dinero",
  receiveCaseBalanceForEveryFriendYourRefer:
    "Reciba el saldo del caso por cada amigo que recomiende",
  howInvitationWork: "¿Cómo funciona la invitación?",
  none: "ninguno",
  debit: "Débito",
  credit: "Crédito",
  transactionBankingUUID: "UUID de transacciones bancarias",
  noDataAvailable: "Datos no disponibles",
  casinoTransactionId: "ID de transacción del casino",
  roundId: "Identificación redonda",
  actionType: "tipo de acción",
  win: "Ganar",
  lose: "perder",
  live: "vivir",
  success: "Éxito",
  pending: "Pendiente",
  cancelled: "Cancelado",
  failed: "Fallido",
  rollBack: "Retroceder",
  approved: "Aprobado",
  rejected: "Rechazado",
  active: "Activo",
  forfeit: "Perdido",
  expired: "Caducado",
  claiming: "Reclamar",
  claimed: "Reclamado",
  inprogress: "En progreso",
  process: "En progreso",
  requested: "solicitado",

  removeMoney: "Quitar dinero",
  addMoney: "Agregar dinero",
  bettingHistoryReport: "Informe del historial de apuestas",
  others: "Otros",
  onlybetsSystem: "Sistema de apuestas únicas",
  uniqueSystemWithVIPSupport:
    "Por supuesto, en uno de los sitios que más ha crecido en las últimas semanas habría un sistema de afiliados para hacerte ganar dinero. ",
  record: "Registro",
  questionRegister: "Cuéntanos qué te motiva a convertirte en afiliado de OnlyBets. ¡Estamos curiosos por saber qué te impulsa a unirte a nosotros!",
  photoComment: "Envía una foto en buena calidad y resolución para resaltar tu perfil.",
  placeHolderQuestionRegister: "Responder con un máximo de 250 caracteres.",
  Registrationform: "Formulario de inscripción",
  idNo: "Número de identificación",
  AffiliateType: "Tipo de afiliado",
  firstName: " Nombre de pila",
  Affiliate: "Afiliado",
  Agency: "Agencia",
  Streammer: "Streamer",
  lastName: "Apellido",
  userName: "Nombre de usuario",
  submit: "Entregar",
  howItWorks: "¿Cómo funciona?",
  stepToSuccessFull:
    "Es muy simple, simplemente siga los tres pasos a continuación para tener éxito.",
  step: "Paso {{number}}",
  affiliateLinkForOpenAccount:
    "Abra una cuenta de afiliado y obtenga su enlace.",
  promptYourLink: "Promociona tu enlace usando la estrategia que quieras",
  toWinEnjoyEarnings: "Ganar. ",
  weWantTYourGoodness: "¡QUEREMOS TU BONDAD!",
  support24_7: "Soporte 24 horas al día, 7 días a la semana",
  weServeYouInRecordTime: "¡Te atendemos en tiempo récord!",
  languageList: "Portugués, Inglés, Español",
  whoAreWe: "¿QUIENES SOMOS?",
  ourDetails:
    "En Onlybets nos enorgullecemos de ser abiertos, transparentes y justos. ",
  chooseOnlyBets: "Elija Solo Apuestas",
  highConversionRates: "Altas tasas de conversión",
  recordAchievingExcellentConversion:
    "Tenemos un historial de lograr excelentes tasas de conversión con nuestros socios. ",
  exceptionalPlayerValues: "Valores excepcionales del jugador",
  loyaltyBasedRetentionStrategy:
    "Nuestra estrategia de retención basada en la lealtad ha demostrado generar un valor excepcional a lo largo de la vida de un jugador. ",
  greatCustomerSupport: "Excelente atención al cliente",
  managersShowEarnPlayerLoyalty:
    "Nuestros dedicados administradores de afiliados le mostrarán cómo ganarse la lealtad de los jugadores y obtener las comisiones que desea. ",
  immediatePayments: "Pagos inmediatos",
  chooseVariousPaymentMethods:
    "Elija entre los diversos métodos de pago que ofrecemos en el sistema. ",
  VIPTraining: "Entrenamiento VIP",
  whatYouWanToWin:
    "¡Queremos que ganes y es por eso que te entregaremos materiales exclusivos y capacitación dedicada para que ganes más!",
  subCategoryName: "Subcategoría",
  preferedCurrency: "Moneda preferida",
  preferredCurrencyIsRequired: "Se requiere moneda preferida",
  selectLanguage: "Seleccione el idioma",
  selectTimezone: "Selecciona la zona horaria",
  selectCountry: "Seleccionar país",
  kyc: "KYC",
  updateKYC: "Actualizar KYC",
  selectGender: "Seleccione género",
  male: "Masculino",
  feMale: "Femenino",
  currentPassword: "Contraseña actual",
  enter: "Ingresar {{text}}",
  noSpaceAllow: "No se permite espacio",
  minCharacters: "mín. {{characterNo}} esta permitido",
  maxCharacters: "máx. {{characterNo}} esta permitido",
  newPasswordTip:
    "La nueva contraseña debe tener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.",
  matchPassword: "La contraseña debe coincidir",
  updateProfile: "Actualización del perfil",
  profileUpdatedSuccessfully: "perfil actualizado con éxito",
  startAmount: "Monto inicial",
  endAmount: "Monto final",
  casinoBet: "Apuesta de casino",
  casinoWin: "Ganancia del casino",
  applyFilterNote:
    "Nota: Para aplicar el filtro, siga la convención como 'Fecha de inicio - Fecha de finalización', 'Hora de inicio - Hora de finalización', 'Monto de inicio - Monto de finalización'",
  bothAreRequired: "*  Comenzar {{text}} - Fin {{text}} ambos son necesarios",
  lessThen: "Menos que",
  greaterThen: "Mas grande que",
  dateShouldBeBetween:
    "{{dateType}} debería estar entre {{minDate}} - {{maxDate}}",
  startCantGreaterThanEnd:
    "Comenzar {{text}} no puede ser mayor que el final {{text}}",
  startDate: "Fecha de inicio",
  endDate: "Fecha final",
  setOnlyRegistered:
    "*  La moneda se puede seleccionar solo en el momento registrado",
  favouriteGames: "Juegos favoritos",
  sports: "Deportes",
  hello: "Hola",
  casinoWelcome: "Oferta de Bienvenida del Casino",
  joinUsTodayToGetBonus:
    "Únete hoy y obtén un bono del 100% hasta $200 y 50 giros gratis para comenzar tu aventura en OnlyBets.",
  moreInformation: "Más información",
  weeklyTournaments: "Torneos Semanales",
  immerseYourselfInTheEmbrace:
    "¡Sumérgete en el Abrazo del Otoño y recoge €5.000 en el Torneo de las Hojas Doradas!",
  loyaltyProgram: "Sistema de Fidelidad",
  WeWantToEnsureThatPlayers:
    "¡Queremos asegurarnos de que los jugadores siempre sean recompensados al jugar en Onlybets!",
  weekendCashbackBooster: "El impulsor de cashback de fin de semana",
  puttingCashbackYourPocket:
    "¡Estamos poniendo un cashback del 15% en tu bolsillo todos los lunes!",
  topWinners: "Principales ganadores",
  usernameIsRequired: "* Se requiere nombre de usuario",
  casinoWelcomeOffer: "Oferta de bienvenida del casino",
  welcomeBonusAndSpins: " ¡BONO DE BIENVENIDA DEL 100% Y 50 GIROS GRATIS! ",
  tncListItem1:
    "Estas reglas complementan las Reglas de bonificación y nuestros Términos generales.",
  tncListItem2:
    "Promoción válida sólo para nuevos jugadores que nunca hayan realizado un depósito en el casino.",
  tncListItem3:
    "Para recibir el bono de esta promoción, simplemente regístrate y haz tu depósito.",
  tncListItem4:
    "El bono de esta promoción es del 100%.",
  tncListItem5:
    "El valor máximo del bono es de R$ 1000,00.",
  tncListItem6:
    "El requisito de apuesta para esta promoción es 35 veces el saldo del bono.",
  tncListItem7:
    "El requisito de apuesta debe cumplirse dentro de los 7 días.",
  tncListItem8:
    "Si no se cumple el requisito de apuesta dentro del plazo, se perderán todos los fondos del bono.",
  tncListItem9:
    "El depósito mínimo para calificar es de R$ 1,00.",
  tncListItem10:
    "Promoción válida solo una vez por persona, hogar, computadora compartida o dirección IP compartida.",
  tncListItem11:
    "La promoción no se puede combinar con otras promociones.",
  tncListItem12:
    "OnlyBets se reserva el derecho de restringir el acceso a esta promoción a cualquier jugador por cualquier motivo que considere oportuno.",
  tncListItem13:
    "En caso de discrepancias entre las versiones en diferentes idiomas de estos términos, prevalecerá la versión en inglés.",
  // tncListItem14: "Esta promoción no es acumulable con otras promociones.",
  // tncListItem15:
  //   "Esta promoción no está disponible para jugadores que utilicen billeteras de criptomonedas.",
  // tncListItem16:
  //   "OnlyBets se reserva el derecho de restringir el acceso a esta promoción a cualquier jugador, por cualquier motivo que considere oportuno.",
  // tncListItem17:
  //   "En caso de inconsistencias entre los diferentes idiomas de estos términos y condiciones, prevalecerá la versión en inglés.",
  listItem1:
    "Sistema de fidelización excepcional: juega y obtén recompensas por cada avance de nivel.",
  listItem2:
    "Bonos de recarga y regalos sorpresa: la diversión nunca termina con nuestros bonos y regalos especiales.",
  listItem3: "Experiencia VIP Exclusiva: Disfrute de un trato VIP incomparable y beneficios exclusivos.",
  introText: "¡Adéntrate en el universo de diversión de OnlyBets!",
  welcomeOfferText:
    "¡Comience su aventura en el casino OnlyBets con una espectacular oferta de bienvenida, hecha a medida para los campeones! Disfrute de un increíble Bono de Bienvenida del 100% hasta R$1000,00 en su primer depósito. ¿Esto te parece algo bueno?",
  moreExcitingText:
    "¡Genial! Porque tenemos muchas más cosas emocionantes que te harán cosquillas:",
  tournamentText:
    "Si eso no es suficiente, ¡no te preocupes! ¡Nuestros torneos semanales, repletos de premios en efectivo, mantendrán tu adrenalina a tope y tu entretenimiento garantizado!",
  goodLuckText:
    "¿Por qué esperar? Con más de 6000 juegos esperándote, la diversión comienza ahora.",
  registerSuccessfull: "Estás registrado exitosamente",
  checkMyProgress: "Verificar mi progreso",
  promotionsTournamentsandMore: "Promociones, torneos y más",
  enhanceYourGamingExperience: "¡Mejora tu experiencia de juego!",
  cashBalance: "Saldo en efectivo",
  totalBalance: "Balance total",
  inSufficientBalanceToPlay: "No tienes fondos suficientes en este momento. ",
  bonusReport: "Informe de bonificación",
  bonusName: "Nombre del bono",

  discoverAll: "Descubre todo",
  clear: "Borrar",
  action: "Acción",
  amountToWager: "Cantidad a apostar",
  expiryDate: "Fecha de caducidad",
  BonusCondition: "Para transformar el bono en saldo real añadido a tu saldo, es necesario superar el importe en apuestas.",
  EmailNotVerifiedError: "Correo electrónico no verificado",
  ThereIsAPendingWithdrawRequestError:
    "Ya hay una solicitud de retiro pendiente.",
  PaymentProviderError: "Se produjo un error al encontrar el proveedor de pago",
  UserIdentificationError:
    "Se produjo un error al validar los datos de identificación del usuario",
  CpfValidationError: "Ocurrió un error al validar el CPF del usuario",
  PixCpfMustBeSameRegisteredError:
    "El CPF informado para el retiro debe ser el mismo que el registrado",
  WalletNotFound: "Monedero no encontrado",
  BalanceError: "Saldo insuficiente",
  CpfInvalidError: "El número CPF no es válido",
  CpfIrregularError: "Número CPF con situación irregular",
  CashOutLimitPerDayError:
    "Retiro de efectivo: el límite de transacción es 1 por documento por día",
  TransactionRepeatedInLess10MinutesError:
    "Transacción repetida en menos de 10 minutos.",
  WithdrawDataError: "Se produjo un error al generar datos de retiro.",
  andGrowing: "¡y creciendo!",
  refund: "Reembolso",
  bet: "Apuesta",
  depositBonusClaim: "Reclamación de bonificación por depósito",
  referralBonusClaim: "Reclamo de bonificación por recomendación",
  joiningBonusClaim: "Unirse al reclamo de bonificación",
  joiningBonus: "Bonificación por unirse",
  referralBonus: "Bono por recomendación",
  depositBonus: "Bono de depósito",
  pageNotFound: "¡No podemos encontrar la página que estás buscando!",
  goToHome: "Ir a casa",
  ooops: "Ups",
  redirectingMessage: "Redireccionando en {{redirectTime}} segundos...",
  IncorrectCurrentPasswordErrorType: "Contraseña actual incorrecta.",


  volatility: "Volatilidad",
  themes: "Temas",
  sportsTransactionReport: "Informe de transacciones deportivas",
  transactionId: "ID de transacción",
  rollback: "Retroceder",
  rollbackbeforebetwin: "Revertir antes de apostar/ganar",
  freespins: "Tiradas gratis",
  betInternal: "Apuesta Interna",
  winInternal: "Ganar interno",
  discard: "Desechar",
  settlement: "Asentamiento",
  refund: "Reembolso",

  CpfCanceledError: "El número CPF está cancelado",
  CpfNotFoundError: "Número CPF no encontrado",
  CpfUnderAgeError: "El número CPF es menor de edad",

  UserNameExists: "Ya existe un usuario registrado con ese nombre",
  UserAlreadyExists: "Ya hay un usuario registrado con este correo electrónico",

  signUpSuccessful: "¡Usuario registrado exitosamente!",

  applyBonus: "Aplicar bonificación",
  bonusAppliedSuccessfully: "Bonificación aplicada con éxito!",
  BonusNotFound: "¡Código de bonificación no encontrado!",
  BonusTypeAlreadyActiveErrorType: "¡Ya tienes activo un bono de este tipo!",
  CpfAlreadyExists: "Ya existe un usuario registrado con este número CPF",

  bonusExpiresIn: "El bono vence en:",
  amountToBeDeposited: "Valor a ser depositado:",
  doYouHaveACoupon: "¿Tienes un cupón?",
  generatePix: "Gerar PIX",
  amountMinValue: "El valor mínimo de depósito es {{minimumDeposit}}",
  maximumDeposit: "El monto máximo del depósito es {{maximumWithdraw}}",
  paymentProvider: "Proveedor de pago",
  scanTheImageToMakePayment: "Escanea la imagen para realizar el pago",

  payment_processed_successfully: "¡Pagamento Processado com Suceso!",
  UserAlreadyExists: "¡El usuario ya existe!",

  reffCode: "Código de referencia",
  apply: "Aplicar",

  withdrawRequestRule1: "Para realizar tu primer retiro, debes apostar una cantidad correspondiente a 1,7 veces el monto de tu depósito inicial. Este criterio debe cumplirse para proceder con el retiro de fondos.",
  withdrawRequestRule2: "Ejemplo: Después de un depósito inicial de R$10,00, debes apostar un mínimo de R$17,00 en juegos de casino o apuestas deportivas para liberar el retiro. Este requisito sólo es válido para el primer retiro.",

  withdrawRequestRule3: "Para realizar tu retiro, debes apostar una cantidad correspondiente a {{rolloverMultiplier}} veces el monto de tus depósitos. Este criterio debe cumplirse para proceder con el retiro de fondos.",
  withdrawRequestRule4: "Ejemplo: Supongamos que el valor de sus depósitos fue {{depositAmountExample}}, debe apostar un mínimo de {{rolloverMultiplierAmount}} en juegos de casino o apuestas deportivas para liberar el retiro. Este requisito es válido para la suma total de su depósitos.",

  UserNotAllowedWithdrawError: "Solicitud de retiro no liberada",
  PixKeyDontBelongToDocumentErrorType: "La clave Pix no pertenece al documento del usuario",
  FirstDepositAmountLimitError: "El límite para el primer depósito debe ser de hasta R$ 100,00",
  INCORRECT_CREDENTIAL: "Este correo electrónico/contraseña es incorrecto",

  minimunMaximunDepositAmount: "Agregue al menos {{minimumDeposit}} y como máximo {{maximumDeposit}}",

  wallet: "Billetera",
  viewBalanceInformation: "Ver información de saldo",
  maximumWithdrawalAmountExceeded: "Monto máximo de retiro excedido",
  minimunMaximunWithdrawAmount: "Agregue al menos {{minimumWithdraw}} y como máximo {{maximumWithdraw}}",
  balanceAvailableForWithdrawal: "Saldo real",

  CashOutMaxValueIs50000ByDocumentPerDayErrorType: 'El valor máximo de retiro de efectivo es R$ 50.000,00 por documento por día',

  claimedSuccessfully: "¡Reclamado exitosamente!",
  depositDouble: "Deposita ahora y obtén el doble de tu depósito hasta {{maximumFirstDeposit}}",

  AffiliateAlreadyExists: "¡Ya hay un usuario registrado!",
  EmailNotAvailableErrorType: "¡Dirección de correo electrónico no disponible!",

  selectAffiliateType: "Selecciona el tipo de afiliado que mejor te represente",

  affiliate: "Afiliado",
  streamer: "Streamer",
  agency: "Agencia",
  other: "Otro",

  affiliateRegistrationSentSuccessfully: "Registro enviado para aprobación, nos comunicaremos en breve.",
  sentSuccessfully: "¡Enviado exitosamente!",
  sentRequest: "Enviar solicitud",
  reviewAllInformation: "Revisa toda la información que ingresaste en los pasos anteriores y, si todo está correcto, envía tu solicitud",
  youWillReceiveReturn: "Recibirás una respuesta en un plazo de 72 horas",

  registrationData: "Datos de registro",
  pleaseEnterRealInformationForApproval: "Por favor ingrese información real para su aprobación.",

  tellUsWhatMotivatesYouToBecomeAnOnlybetsAffiliate: "Dinos qué te motiva a convertirte en afiliado de Onlybets.",
  WereCuriousToKnowWhatMakesYouWantToJoinUs: "¡Tenemos curiosidad por saber qué te hace querer unirte a nosotros!",

  iHaveReadAndAcceptThePrivacyPolicy: "He leído y acepto la Política de Privacidad",

  iWouldLikeToBeAnAffiliate: "Me gustaría ser un Afiliado",
  iWouldLikeToBeAnStreamer: "Me gustaría ser Streamer",
  iWouldLikeToBeAnAgency: "Me gustaría ser Agencia",
  iWouldLikeToBeAnOther: "Me gustaría ser otro tipo de afiliado",

  affiliateTypeAffiliateDescription: "Traer nuevos usuarios, motivarlos, usar mis redes sociales y estrategias de marketing para atraer jugadores y recibir comisiones por ello.",
  affiliateTypeStreamerDescription: "Además de atraer nuevos usuarios, me gustaría usar mi carisma y conocimiento para hablar con los jugadores y motivarlos a través de transmisiones en vivo en la plataforma",
  affiliateTypeAgencyDescription: "Tengo la intención de traer nuevos afiliados y/o streamers a la plataforma, asegurando un crecimiento exponencial, pero no tengo la intención de transmitir ni motivar a los jugadores directamente.",
  affiliateTypeOtherDescription: "Traer nuevos usuarios, motivarlos, usar mis redes sociales y estrategias de marketing para atraer jugadores y recibir comisiones por ello.",

  close: "Cerrar",

  passwordValidationMessage: "La contraseña debe incluir al menos 8 caracteres con letras y/o números y no debe incluir espacios.",

  yourProgress: "Tu progreso",
  completed: "Completado",

  weNoticedThatYouAreNotYetConnectedToOurPlatform: "Hemos notado que aún no estás conectado a nuestra plataforma.",
  toAccessChooseOneOfTheOptionsBelow: "Para acceder, elija una de las siguientes opciones:",

  iWantToRegister: "Quiero registrarme",
  iAmAuser: "Ya soy usuario",

  welcomeTo: "Bienvenido a",
  needHelp: "¿Necesitas ayuda?",
  clickHere: "Haga clic aquí",

  yourFirstTimeItsSafeAndEasyToUse: "¿Tu primera vez?",
  makeYourFirstDepositAndPlayTogetherWithYourFavoriteInfluencers: "Regístrate y juega ahora con una de nuestras personalidads.",
  makeAMinimumDepositToPlayWithTheInfluencer: "Haz un depósito para jugar con tu Influencer",
  makeADeposit: "Hacer un depósito",

  girsFromAllOverBrazilWantToKeepYouCompany: "Personalidads de todo Brasil quieren hacerte compañía",
  dontHaveAnyDoubtsWeAlwaysHaveSomeoneAvailableToHelpYou: "No tengas dudas, siempre tenemos a alguien disponible para ayudarte.",

  wouldYouLikeToPlayWhileWatchingOneOfOurGirlsLive: "¿Te gustaría jugar viendo a una de nuestras personalidads en vivo?",

  bonusAvailable: "Bono disponible",
  termsAndCondiction: "Términos y condiciones",
  use: "Usar",
  select: "Seleccionar",
  selected: "Seleccionado",

  congratulationsYouHaveReceivedTheBonus: 'Felicitaciones, ha recibido el bono "{{bonusName}}", haga clic en el botón a continuación para obtener más detalles',
  accessMyBonuses: "Acceder a mis bonos",

  bonusActive: "Bono activo",
  bonusAmount: "Valor del bono",
  betValueForCompletion: "Cantidad de la apuesta para completar",
  wageringRequirements: "Requisitos de apuesta",

  days: "Días",
  hours: "Horas",
  minutes: "Minutos",
  seconds: "Segundos",

  timeRemainingForWagering: "Tiempo restante para apostar",
  congratulationsYouHaveClaimedYourBonus: "¡Felicitaciones, reclamaste tu bono por valor de {{redemptionBonus}} con dinero real!",

  activateNow: "Activar ahora",
  bonusActivatedSuccessfully: "Bono activado exitosamente!",
  BonusActiveErrorType: "Ya tienes un bono Activo",
  BonusMinAmountErrorType: "Monto mínimo de depósito para bono no alcanzado",
  BonusMaxAmountErrorType: "Se excedió el monto máximo de depósito para el bono",

  active: "Ativar",
  activated: "Activado",

  dontForgetToActivateYourBonus: "No olvides activar tu bono",
  ifYouAlreadyHaveARegistration: "Si ya te has registrado",

  DepositTransactionPendindErrorType: "¡Estado pendiente de transacción de depósito!",

  promotionPageCardBonusTitle: "¡Plataforma regulada en Brasil!",
  promotionPageCardBonusSubtitle: "¡Cumplimos al 100% con la legislación brasileña!",

  regulationText1: "¡Onlybets.tv está regulada en Brasil!",
  regulationText2: "¿Qué significa esto para usted?",
  regulationText3: "✅ Seguridad y transparencia: Operamos dentro de todos los requisitos legales establecidos por el Gobierno brasileño, lo que garantiza que sus apuestas se realicen en un entorno seguro y confiable",
  regulationText4: "✅ Tranquilidad: a partir del 1 de octubre de 2024, las plataformas que no cumplan con las reglas de la Ordenanza MF/SPA No. 1475 del 17 de septiembre de 2024 ya no podrán operar. Sin embargo, en Onlybets, puede apostar con la confianza de que estamos en proceso de regulación",
  regulationText5: "¡Apuesta con seguridad y juega con aquellos que realmente entienden el placer!🔥",
  regulationText6: "Gracias por elegir Onlybets, donde tu bienestar es nuestra prioridad! 🎰✨",
  regulationText7: "+18 | Juega responsablemente 🔞",

  watchLive: "Assitir live",
  Congratulations: "¡Felicidades!",
  youHaveReleaseYyourBonus: "¡Has alcanzado el objetivo de acumulación y has liberado tu bono!",

  playSafeAndHaveFun: "Juega con seguridad y diviértete",
  weAreInComplianceWithBettingRegulationsClickAndFindOutMore: "Cumplimos al 100% con las normas de apuestas, haz clic y descubre más.",
  findOutMore: "Más información",

  clickToClaiming: "Haga clic para reclamar",
  gotToBonusCategory: "Ir a juegos de bonificación",
  goBackCasino: "Regreso al casino",
  accessSubCategoryBonusDialog: "El juego que seleccionaste no está disponible para el bono actual. Ve a la sección de juegos elegibles para el bono y continúa divirtiéndote. ¡Buena suerte!",

  depositWithBonusActive: "¡Atención! Al continuar con este depósito, la promoción de bonificación actualmente activa se cancelará. Sin embargo, podrás seguir accediendo y jugando a todos los juegos normalmente. Asegúrate de que esta sea tu elección antes de continuar",

  theWorldsFirstPlatformWhereYouPlayAlongsideCelebrities: "La primera plataforma del mundo donde juegas junto a celebridades",
  achieveVictoriesAlongsideYourFavoritePersonality: "Logra victorias junto a tu personalidad favorita",

  noLiveStreamsAtTheMoment: "No hay transmisiones en vivo en este momento",
  noStreamersPlayingAtTheMoment: "No hay streamers jugando en este momento",

  personalities: "Personalidads",

  cookiesConsentTitle: "Consentimiento de cookies",
  cookiesConsentDescription: 'Utilizamos cookies para mejorar su experiencia de navegación, ofrecer anuncios o contenido personalizados y analizar el tráfico. Al hacer clic en "Aceptar todo", aceptas el uso de cookies.',
  cookiesPolicy: "Política de cookies",

  acceptAll: "Aceptar todo",
  reject: "Rechazar",
  preferences: "Preferencias",

  cookiesPreferencesTitle: "Información básica sobre cookies",
  cookiesPreferencesDescription: "Cuando visita cualquier sitio web, este puede obtener o guardar información en su navegador, generalmente mediante el uso de cookies. Esta información puede ser sobre usted, sus preferencias o su dispositivo, y se utiliza principalmente para que el sitio web funcione como previsto. La información generalmente no lo identifica directamente, pero puede brindarle una experiencia web más personalizada. Debido a que respetamos su derecho a la privacidad, puede optar por no permitirnos utilizar ciertas cookies en nuestra Política de cookies. obtenga más información y cambie nuestra configuración predeterminada. Sin embargo, bloquear algunos tipos de cookies puede afectar su experiencia en el sitio y la funcionalidad que podemos ofrecer.",
  consentPreferencesManager: "Administrar preferencias de consentimiento",

  requireCookies: "Cookies estrictamente necesarias",
  functionalitiesCookies: "Cookies de Funcionalidades",
  performanceCookies: "Cookies de rendimiento",
  analyticsCookies: "Cookies de análisis",

  requireCookiesDescription: "Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Por lo general, se configuran para responder a las acciones que usted realiza para recibir servicios, como ajustar sus preferencias, privacidad, iniciar sesión en el sitio web o completar formularios. Puede configurar su navegador para bloquear o alertar de la presencia de estas cookies, pero algunas partes del sitio no funcionarán",
  functionalitiesCookiesDescription: "Estas cookies permiten que el sitio web proporcione una mejor funcionalidad y personalización. Podemos configurarlas nosotros o terceros cuyos servicios hemos agregado a nuestras páginas. Si no permite estas cookies, algunos de nuestros servicios no funcionarán correctamente .",
  performanceCookiesDescription: "Estas cookies nos permiten contar visitas y fuentes de tráfico para medir y mejorar el rendimiento de nuestro sitio web. Nos ayudan a saber qué páginas son más o menos populares y ver cuántas personas visitan el sitio web. Toda la información recopilada por estas Cookies es agregadas y, por tanto, anónimas. Si no permite estas cookies, no sabremos cuándo ha visitado nuestro sitio web y, por lo tanto, no podremos saber cuándo lo ha visitado.",
  analyticsCookiesDescription: "Estas cookies pueden ser de todo el sitio y ser colocadas por nuestros socios publicitarios. Estas cookies pueden ser utilizadas por estas empresas para crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios web. No almacenan información personal directamente, pero se basan en la identificación única de su navegador y dispositivo de acceso a Internet. Si no permite estas cookies, experimentará publicidad menos dirigida.",

  saveMyPreference: "Guardar mis preferencias",
  cookiesPreferences: "Preferencias de cookies",

  otpCode: "Código de verificación",
  otpCodeRequired: "Se requiere código de verificación",
  otpCodeSendedForEmail: "Código de verificación enviado a su correo electrónico",
  otpCodeSendedForEmailInfo: "Se ha enviado un código de verificación (contraseña de un solo uso) a su correo electrónico. Revise su bandeja de entrada e ingrese el código para continuar. Si no puede encontrar el correo electrónico, revise también su carpeta de correo no deseado o desechos electrónicos.",

  OTPCodeNotSended: "Ocurrió un error al enviar el código de verificación OTP a su correo electrónico",
  OTPCodeIsNotValid: "El código de verificación OTP no es válido",
  OTPCodeRequired: "¡Se requiere código de verificación OTP!",
  OTPCodeExpiredOrNotExists: "¡El código de verificación OTP expiró!",
  GenerateOTPErrorType: "Error al generar código OTP",
  sendNewOtpCode: "Enviar nuevo código OTP",

  OTPCodeRequestTitle: "¿Habilitar solicitud de código OTP al iniciar sesión?",
  OTPCodeDescription: "El código OTP (contraseña de un solo uso) es una contraseña temporal generada para garantizar la seguridad de una transacción o autenticación. Se utiliza para verificar la identidad del usuario y generalmente se envía por correo electrónico, SMS o aplicación de autenticación. El código tiene validez limitada y sólo se puede utilizar una vez, lo que proporciona una capa adicional de protección contra el acceso no autorizado.",

  disable: "Desactivar",
  otpRequestValidationEmailSended: "Hemos enviado un correo electrónico para confirmar la habilitación de la solicitud del código OTP en su inicio de sesión. Revise su bandeja de entrada y siga las instrucciones para completar la activación. Si no puede encontrar el correo electrónico, revise también la bandeja de entrada de correo no deseado o correo no deseado.",

  maintenanceTitle: "Estamos realizando una migración del sistema.",
  maintenanceDescription: "Nuestros servicios no están disponibles temporalmente. ¡Estamos trabajando para volver a estar en línea pronto y agradecemos su comprensión!",
  UserOnTemporarySuspension: "Su cuenta está suspendida temporalmente",

  userLimits: "Límites de usuario",
  toolsTostayInControl: "Herramientas para mantener el control",

  saveChanges: "Guardar cambios",
  theDepositLimitMayBeChangedAt: 'El límite de depósito puede cambiarse nuevamente el: {{date}}',
  NoValueChangesMadeError: "No se realizaron cambios de valor",
  DepositLimitChangesByDaysExceededError: "¡Se excedieron los cambios en el límite de depósito por día!",

  CasinoBetLimitChangesByDaysExceededError: "¡Se excedieron los cambios en el límite de pérdidas del casino por día!",
  SportsBetLimitChangesByDaysExceededError: "¡Se excedieron los cambios en el límite de pérdidas de apuestas deportivas por día!",
  CasinoLossLimitChangesByDaysExceededError: "¡Se excedieron los cambios en el límite de pérdidas del casino por día!",
  SportsLossLimitChangesByDaysExceededError: "¡Se excedió el límite de pérdida de apuestas deportivas por día!",
  CasinoBetLimitQuantityChangesByDaysExceededError: "¡Se excedió el límite de cantidad de apuestas de casino por día!",
  SportsBetLimitQuantityChangesByDaysExceededError: "¡Se excedió el límite de cantidad de apuestas deportivas por día!",

  DailyDepositLimitReachedTypeError: "Se alcanzó el límite de depósito diario",
  WeeklyDepositLimitReachedTypeError: "Se alcanzó el límite de depósito semanal",
  MonthlyDepositLimitReachedTypeError: "Se alcanzó el límite de depósito mensual",

  limitAmountIsRequired: "Se requiere el monto del límite",
  limitCountIsRequired: "Se requiere el monto del límite",
  limitPeriodIsRequired: "Se requiere el período de tiempo",
  timeLimitIsRequired: "Se requiere el límite de tiempo",

  depositLimits: "Límites de depósito",
  depositLimitsDescription: "Un límite de depósito le permite establecer una cantidad específica de dinero que puede depositar durante un período de tiempo determinado",
  limitValue: "Valor límite",
  quantityValue: "Monto límite",
  limitPeriod: "Período de tiempo",
  timelimitInHours: "Límite de tiempo en horas",

  betLimits: "Límites de apuestas",
  betLimitsDescription: "Los límites de apuestas le permiten establecer una cantidad específica de dinero que puede apostar durante un período de tiempo determinado.",

  lossLimits: "Límites de pérdidas",
  lossLimitsDescription: "Los límites de pérdidas le permiten establecer una cantidad específica de dinero que puede perder durante un período de tiempo determinado.",

  theCasinoBetLimitMayBeChangedAt: 'El límite de apuestas del casino puede actualizarse nuevamente a partir del {{date}}',
  theSportsBetLimitMayBeChangedAt: 'El límite de apuestas deportivas puede actualizarse nuevamente a partir del {{date}}',
  theCasinoLossLimitMayBeChangedAt: 'El límite de pérdidas del casino puede actualizarse nuevamente a partir del {{date}}',
  theSportsLossLimitMayBeChangedAt: 'El límite de pérdidas deportivas puede actualizarse nuevamente a partir del {{date}}',
  theCasinoBetLimitQuantityMayBeChangedAt: 'El límite de apuestas del casino puede actualizarse nuevamente a partir del {{date}}',
  theSportsBetLimitQuantityMayBeChangedAt: 'El límite de apuestas deportivas puede actualizarse nuevamente a partir del {{date}}',
  theSessionTimeLimitMayBeChangedAt: 'El límite de tiempo de la sesión puede actualizarse nuevamente a partir del {{date}}',

  breakPeriod: "Período de interrupción",
  breakPeriodDescription: "El período de interrupción se activa una vez que establece un período y guarda los cambios, lo que le impide realizar apuestas o jugar juegos de casino durante el período seleccionado.",
  breakPeriodIsActiveUntil: 'El período de interrupción está activo hasta el {{date}}',

  betLimitsQuantity: "Límites de apuestas",
  betLimitsDescriptionQuantity: "El límite de apuestas le permite establecer una cantidad específica de apuestas que puede realizar.",

  UserWithActiveBreakPeriodError: "¡Período de descanso activado!",
  userWithActiveBreakPeriod: 'Tiene un período de descanso activo hasta el {{date}}',

  selectBreakPeriod: "Seleccione período de descanso",

  sessionTimeLimit: "Limite de tempo da sessão",
  sessionTimeLimitDescription: "O limite de tempo da sessão permite que você defina uma quantidade específica de tempo em horas que você pode usar.",

  UserSessionTimeLimitDailyReached: "Limite de tempo da sessão diária atingido!",
  UserSessionTimeLimitWeeklyReached: "Limite de tempo da sessão semanal atingido!",
  UserSessionTimeLimitMonthlyReached: "Limite de tempo da sessão mensal atingido!",

  DailyCasinoBetQuantityLimitReached: "Você atingiu seu limite de valor de aposta diária no cassino!",
  WeeklyCasinoBetQuantityLimitReached: "Você atingiu seu limite de valor de aposta semanal no cassino!",
  MonthlyCasinoBetQuantityLimitReached: "Você atingiu seu limite de valor de aposta mensal no cassino!",

  DailyCasinoBetAmountLimitReached: "Você atingiu seu limite de valor de aposta diária no cassino!",
  WeeklyCasinoBetAmountLimitReached: "Você atingiu seu limite de aposta semanal no cassino!",
  MonthlyCasinoBetAmountLimitReached: "Você atingiu seu limite de aposta mensal no cassino!",
  DailyCasinoLossAmountLimitReached: "Você atingiu seu limite de perda diária no cassino!",
  WeeklyCasinoLossAmountLimitReached: "Você atingiu seu limite de perda semanal no cassino!",
  MonthlyCasinoLossAmountLimitReached: "Você atingiu seu limite de perda mensal no cassino!",
  sessionTimeDailyPeriodMax: "O valor máximo para o período diário é de 24 horas",
  sessionTimeWeeklyPeriodMax: "O valor máximo para o período semanal é de 168 horas",
  sessionTimeMonthlyPeriodMax: "O valor máximo para o período mensal é de 720 horas",
  youHaveATotalBet: "Você fez um total de {{count}} apostas, seu limite é {{quantityLimit}}!",
  youBetATotalOf: "Você apostou um total de {{amount}}, seu limite é {{amountLimit}}!",
  youLossATotalOf: "Você perdeu um total de {{amount}} em apostas de cassino, seu limite de perda é {{amountLimit}}!",

  TheBetAmountIsGreaterThanYourConfiguredLimit: "O valor da aposta foi maior que seu limite configurado, você apostou um total de {{betAmount}}, mas seu limite é {{betLimit}}.",

  diario: "Diariamente",
  semanal: "Semanalmente",
  mensual: "Mensualmente",
};
